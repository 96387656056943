import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout>    
  <div>
    <h1>You can get hold of me here on Twitter</h1>
    <p>
      <a href="https://twitter.com/davehallonline">@davehallonline</a>
    </p>
  </div>
  </Layout>  
)